import { graphql } from "gatsby"
import React from "react"
import CareersPage from "../../components/pages/Careers"

const lang = "es"

const Careers = ({ data, pageContext }) => {
  const { seoDataJson } = data
  const seoData = {
    url: seoDataJson.url_es,
    titleSeo: seoDataJson.title_es,
    description: seoDataJson.description_es,
    alternateLanguage: seoDataJson.alternateLanguage_es,
    alternateUrl: seoDataJson.alternateUrl_es,
  }

  const [resultUrls] = pageContext.urls.filter(obj => {
    return obj.id === "CAREERS"
  })

  return (
    <CareersPage lang={lang} data={data} seoData={seoData} urls={resultUrls} />
  )
}

export const pageQuery = graphql`
  query careersEsQuery {
    careersImagesDesktop: allFile(
      filter: {
        relativePath: { glob: "group/careers/desktop/*" }
        extension: { eq: "jpg" }
      }
    ) {
      nodes {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    careersImagesMobile: allFile(
      filter: {
        relativePath: { glob: "group/careers/mobile/*" }
        extension: { eq: "jpg" }
      }
    ) {
      nodes {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    seoDataJson(name: { eq: "careers" }) {
      url_es
      title_es
      description_es
      alternateLanguage_es
      alternateUrl_es
    }
    allCareersJson(filter: { lang: { eq: "es" } }) {
      edges {
        node {
          name
          title
          sendTo
          lang
        }
      }
    }
  }
`

export default Careers
